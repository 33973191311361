<template>
	<el-drawer
		title="查看客户批复信息"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="70%"
		@open="open"
		@close="
			() => {
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-card class="box-card">
					<div
						class="desc"
						:class="{
							activeColor:
								item.schemeApprovalStatus != 5 &&
								item.schemeApprovalStatus != 6 &&
								item.schemeApprovalStatus != 7,
						}"
					>
						{{
							item.schemeApprovalStatus == 5 ||
							item.schemeApprovalStatus == 6 ||
							item.schemeApprovalStatus == 7
								? '已完结'
								: '进行中'
						}}
					</div>
					<p
						@click="showDialogVisible(item)"
						class="content-title"
						style="
							font-size: 22px;
							font-weight: 800;
							color: #23924f;
							cursor: pointer;
							display: flex;
							align-items: center;
						"
					>
						{{ item.clientName }}
						<span
							style="
								color: red;
								font-weight: 400;
								font-size: 15px;
							"
							>({{ item.schemeApprovalTypeName }})</span
						>
					</p>
					<div style="padding: 10px 10px">
						<div class="content-box">
							<div>
								<p>产品名称：{{ item.financialProductName }}</p>
								<p>批复时间：{{ item.approvalTime }}</p>
								<p>批复金额(万元)：{{ item.approvalAmount }}</p>
								<p>批复利率/费率(%)：{{ item.approvalRate }}</p>

								<p>
									贷款担保条件：{{
										item.loanGuaranteeConditions
									}}
								</p>
								<p>
									贷款前提条件：{{ item.loanPrerequisites }}
								</p>
								<p>
									其他配合要求：{{ item.otherRequirements }}
								</p>
							</div>
							<div>
								<p>融资期限(月)：{{ item.loanDeadline }}</p>
								<p>
									批复有效期(月)：{{
										item.approvalValidityTerm
									}}
								</p>
								<p>每月还款日：{{ item.repaymentDate }}</p>
								<p>还款方式：{{ item.repaymentMethod }}</p>
							</div>
						</div>
					</div>
					<div style="position: relative">
						<myStateItem :item="item" :stateList="stateList" />
						<div style="display: flex; justify-content: flex-end">
							<el-button
								type="primary"
								@click="editData(item, 'stateVisible')"
								v-if="
									item.schemeApprovalStatus != 5 &&
									item.schemeApprovalStatus != 6 &&
									item.schemeApprovalStatus != 7 &&
									item.currentApprovalLink != 7
								"
								>批复环节落实
							</el-button>
							<el-button
								type="primary"
								@click="editData(item, 'editVisible')"
								v-if="
									item.schemeApprovalStatus != 5 &&
									item.schemeApprovalStatus != 6 &&
									item.schemeApprovalStatus != 7
								"
								>修改
							</el-button>
							<el-button type="primary" @click="feedback(item)"
								>反馈进度及意见
							</el-button>
						</div>
					</div>
				</el-card>
				<div class="demo-drawer__footer">
					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
		<stateChange
			class="stateChange"
			:stateList="stateList"
			@closeVisible="stateVisible = false"
			:params="params"
			:stateVisible.sync="stateVisible"
			@success="success"
		/>
		<editDrawer
			@successVisible="editSuccess"
			@closeVisible="editVisible = false"
			:params="params"
			:editVisible.sync="editVisible"
		/>
		<publicReply :paramsId="params" :replyVisible.sync="replyVisible" />
		<publicInfoDrawer
			:allData="allData"
			:privacyType="privacyType"
			:dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId"
			@closeVisible="publicDialogVisible = false"
		/>
	</el-drawer>
</template>

<script>
import { itemByType } from '@/api/index.js';
import myStateItem from '../../approval/module/stateItem.vue';
import { myMixin } from '@/mixins';
import stateChange from '../../approval/module/stateChange.vue';
import editDrawer from '../../approval/module/editDrawer.vue';

export default {
	name: 'approvalDrawer',
	props: {
		approvalVisible: {
			type: Boolean,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},
	mixins: [myMixin],

	data() {
		return {
			stateList: [],
			stateVisible: false,
			editVisible: false,
			replyVisible: false,
			params: {},
			SchemeFailReasonTypeList: [],
		};
	},
	methods: {
		success(data) {
			this.params.currentApprovalLinkName = data.currentApprovalLinkName;
			this.params.currentApprovalLink = data.currentApprovalLink;
			this.params.nextApprovalLinkName = data.nextApprovalLinkName;
			this.params.nextApprovalLink = data.nextApprovalLink;
			this.$emit('changeList', data);
			this.stateVisible = false;
		},
		editSuccess(obj) {
			this.$emit('replaceList', obj);

			this.editVisible = false;
		},
		async getSchemeStatus() {
			const res = await itemByType({
				dictionaryType: 'SchemeImplementCondition',
			});
			if (res.returncode === 0) {
				this.stateList = res.data;
			}
		},
		async getSchemeFailReasonTypeList() {
			const res = await itemByType({
				dictionaryType: 'SchemeFailReasonType',
			});
			if (res.returncode === 0) {
				this.SchemeFailReasonTypeList = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		feedback(item) {
			this.params = item;
			this.params.businessType = 10;

			this.replyVisible = true;
		},
		editData(item, Visible) {
			this.params = item;
			this[Visible] = true;
		},
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		open() {
			this.getSchemeStatus();
			this.getSchemeFailReasonTypeList();
		},
	},
	created() {},
	components: {
		myStateItem,
		stateChange,
		editDrawer,
	},
	computed: {
		drawer_: {
			get() {
				return this.approvalVisible;
			},
			set(v) {
				this.$emit('update:approvalVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}

.inquiry {
	width: 100%;
	background: #fff;
	overflow: auto;
	position: relative;
}
.info {
	line-height: 20px;
	margin: 10px 15px 20px 15px;
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
</style>
<style lang="scss" scoped>
div {
	box-sizing: border-box;
}
.scheme {
	width: 100%;
	background: #fff;
	position: relative;
}
.content-title {
	color: #000;
	font-weight: 800;
}
.content-box {
	display: flex;
	padding: 0 10px;
	div {
		flex: 1;
	}
	p {
		font-size: 14px;
		color: #888;
		margin: 20px 10px;
	}
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
</style>
